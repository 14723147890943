<template>
    <div>
        <div class="main-title">直播用户</div>
        <div class="content-list-page page-content">
            <div class="list-head-box">
                <div data-v-69874ad0="" class="list-head" >
                    <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
                    <ul data-v-69874ad0="">
                        <li data-v-69874ad0="">添加用户后会自动同步到class in后台</li>
                        <li data-v-69874ad0="">用户初始密码为手机号后6位</li>
                    </ul>
                </div>
            </div>


            <div class="padding-t-sm padding-b-sm">
                <span class="fl-l" >
                    <el-input clearable placeholder="请输入姓名" v-model="searchName" />
                </span>

                <span class="fl-l margin-l-sm" >
                    <el-input clearable placeholder="请输入手机号" v-model="searchPhone" />
                </span>
                <span class="margin-l-sm fl-l">
                    <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
                </span>

                <span class=" fl-r">
                    <el-button type="primary" size="small" @click="showDialog" >新增</el-button>
                </span>

                <div style="clear: both"></div>

            </div>


            <div class="table-content">

                <el-table
                        :header-cell-style="{padding:0}"
                        ref="singleTable"
                        :data="tableData"
                        highlight-current-row
                        v-loading="loading"
                        style="width: 100%;background: #fff;">
                    <el-table-column
                            label="ID"
                            property="id"
                            width="80">
                    </el-table-column>
                    <el-table-column
                            label="真实姓名" >
                        <template slot-scope="scope">
                            <span>{{scope.row.realname}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="手机号"  >
                        <template slot-scope="scope">
                            <span>{{scope.row.phone}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="类型">
                        <template slot-scope="scope">
                            <span>{{scope.row.type === 1 ? '学生' : '教师'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="created_at"
                            label="创建时间" width="180">
                        <template slot-scope="scope">
                            <span>{{scope.row.created_at}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="classIn ID" >
                        <template slot-scope="scope">
                            <span>{{scope.row.uid}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="用户id" >
                        <template slot-scope="scope">
                            <span>{{scope.row.user_id}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="index"
                            label="用户昵称" >
                        <template slot-scope="scope">
                            <span>{{scope.row.user_name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="address"
                            label="状态" >
                        <template slot-scope="scope">
                            <span v-if="scope.row.status === 1" class="state-up"><i></i>正常</span>
                            <span v-if="scope.row.status === 2" class="state-down"><i></i>停用</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            property="address"
                            label="操作">
                        <template slot-scope="scope">
                            <span>
                                <el-link v-if="scope.row.type === 2 && scope.row.status === 2" type="primary" @click="setStatus(scope.row,1)">启用</el-link>
                                <el-link v-if="scope.row.type === 2 && scope.row.status === 1" type="primary" @click="setStatus(scope.row,2)">停用</el-link>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>

            </div>

            <div class="table-batch" >
                <span class="fl-r" >
                    <el-pagination
                            small
                            background
                            :current-page="page"
                            :page-sizes="[10, 20]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            @size-change="setPageSize"
                            @current-change="setPage"
                            :total="total">
                </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>
        </div>

        <el-dialog
                title="添加用户"
                :show-close="false"
                :close-on-click-modal="false"
                append-to-body
                :visible.sync="dialogVisible"
                width="400px">
            <div>
                <el-form :rules="rules"  ref="form" :model="form" label-width="110px">
                    <el-form-item label="手机号：" prop="phone">
                        <el-input clearable size="mini" v-model="form.phone"></el-input>
                    </el-form-item>
                    <el-form-item label="真实姓名：" prop="realname">
                        <el-input clearable size="mini" v-model="form.realname"></el-input>
                    </el-form-item>
                    <el-form-item label="用户id：" prop="userId">
                        <el-input clearable size="mini" v-model="form.userId"></el-input>
                    </el-form-item>
                    <el-form-item label="用户类型：" prop="type">
                        <el-select clearable size="mini" style="width: 100%" v-model="form.type" placeholder="请选择">
                            <el-option label="学生" :value="1" />
                            <el-option label="教师" :value="2" />
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
            <el-button size="mini" @click="hideDialog">取 消</el-button>
            <el-button size="mini" type="primary" @click="addLiveUser" :loading="submitLoading">确 定</el-button>
          </span>
        </el-dialog>

    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "template-list",
        data(){
            return {
                rules: {
                    realname: [
                        { required: true, message: '请输入真实姓名', trigger: 'blur' },
                    ],
                    phone: [
                        { required: true, message: '请输入手机号', trigger: 'blur' },
                    ],
                    userId: [
                        { required: true, message: '请输入小程序用户id', trigger: 'blur' },
                    ],
                    type: [
                        { required: true, message: '请你选择类型', trigger: 'change' },
                    ],
                },
                searchName:'',
                searchPhone:'',
                dialogVisible:false,
                page:1,
                pageSize:10,
                total:0,
                loading:false,
                imageUrl:config.imageUrl,
                value: '',
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                value2: '',
                tableData: [],
                form:{
                    phone:'',
                    realname:'',
                    type:'',
                    userId:'',
                },
                submitLoading:false,
            }
        },
        methods:{
            ...mapActions('live',['addUser','getLiveUser','setTeacherStatus']),
            showDialog(){
                this.dialogVisible = true
            },
            hideDialog(){
                this.dialogVisible = false
            },
            setStatus(row,status){
                this.setTeacherStatus({id:row.id,status:status}).then(res => {
                    if(res.ret===0){
                        this.$message.success('操作成功！')
                        this.getList()
                    }
                })
                console.log(row)
            },
            addLiveUser(){
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.submitLoading = true
                        this.addUser(this.form).then(res => {
                            if(res.ret===0){
                                this.$message.success('添加成功！')
                                this.getList()
                                this.$refs['form'].resetFields()
                                this.hideDialog()
                            }
                        }).finally(() => {
                            this.submitLoading = false
                        })


                    }
                });
            },
            search(){
                this.tableData = []
                this.page = 1
                this.getList()
            },
            getList(){
                this.loading = true
                let data = {
                    realname:this.searchName,
                    phone:this.searchPhone,
                    page:this.page,
                    pageSize:this.pageSize
                }
                this.getLiveUser(data).then(res => {
                    this.tableData = res.data.list
                    this.total = res.data.total
                }).finally(() =>{
                    this.loading = false
                })

            },
            setPageSize(pageSize){
                this.pageSize = pageSize
                this.getList()
            },
            setPage(page){
                this.page  = page
                this.getList()
            },
        },
        mounted() {
            this.getList()
        }
    }
</script>

<style>

</style>
